// Animatie - meerdere animaties mogelijk, ; gescheiden
.animate(@props... ) {
    @prop: replace("@{props}", ' ', ',');
    transition-property: @prop;
    transition-duration: .2s;
    transition-timing-function: linear;
}

// Icon
.icon(@icon: '\e814', @font-size: 24, @line-height: 1) {
    content: @icon;
    font-family: @icon-font;
    font-weight: normal;
    font-style: normal;
    font-size: (@font-size * 1px);
    letter-spacing: normal;
    text-transform: none;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;

    & when (@line-height > 1) {
        line-height: (@line-height * 1px);
    }

    & when (@line-height =1) {
        line-height: @line-height;
    }
}

//lias Icon
.icon-replace-fill(@src, @pri-new, @sec-new) {
    @escape-pri-default: escape(#161616);
    @escape-sec-default: escape(#d00004);
    @escape-pri-new: escape(@pri-new);
    @escape-sec-new: escape(@sec-new);
    @data-uri: data-uri('image/svg+xml;charset=UTF-8', "@{src}");
    @pri-src: replace("@{data-uri}", "@{escape-pri-default}", "@{escape-pri-new}");
    @sec-src: replace("@{pri-src}", "@{escape-sec-default}", "@{escape-sec-new}");
    background-image: e(@sec-src);
}


// Clearfix // Float clear
.clearfix() {
    *zoom: 1;

    &::before,
    &::after {
        display: table;
        border-spacing: 0;
        line-height: 0;
        content: '';
    }

    &::after {
        clear: both;
    }
}

// Container breedte
.container(@max-width: 1200, @top-margin: 0, @position: static) {
    width: calc(~'100% - '(80/@rem));
    max-width: (@max-width/@rem);
    margin: @top-margin auto 0;
    position: @position;
}

// Reset the default list styling
.reset-list(@margin: 0, @padding: 0) {
    list-style: none;
    margin: @margin;
    padding: @padding;
}

// Reset the list to remove all the ugly styles
.reset-btn(@background-color: transparent, @border: 0 none, @padding: 0) {
    background-color: @background-color;
    border: @border;
    padding: @padding;
    text-decoration: none;
    cursor: pointer;
}

// Vertical align center
.v-align() {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Implement the xd / ps letterspacing
.letter-spacing(@kerning) {
    letter-spacing: (@kerning / 1000em)
}

// Material shadows
.shadow(@level: 0) {
    & when (@level =0) {
        box-shadow: none;
    }

    & when (@level =1) {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.26), 0 1px 3px rgba(0, 0, 0, 0.08);
    }

    & when (@level =2) {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26), 0 1px 5px rgba(0, 0, 0, 0.08);
    }

    & when (@level =3) {
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.26), 0 1px 8px rgba(0, 0, 0, 0.08);
    }

    & when (@level =4) {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.26), 0 1px 10px rgba(0, 0, 0, 0.08);
    }

    & when (@level =5) {
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.26), 0 3px 5px rgba(0, 0, 0, 0.08);
    }

    & when (@level =6) {
        box-shadow: 0 8px 10px rgba(0, 0, 0, 0.26), 0 4px 15px rgba(0, 0, 0, 0.08);
    }

    & when (@level =7) {
        box-shadow: 0 9px 12px rgba(0, 0, 0, 0.26), 0 5px 6px rgba(0, 0, 0, 0.08);
    }

    & when (@level =8) {
        box-shadow: 0 12px 17px rgba(0, 0, 0, 0.26), 0 7px 8px rgba(0, 0, 0, 0.08);
    }

    & when (@level =9) {
        box-shadow: 0 16px 24px rgba(0, 0, 0, 0.26), 0 8px 10px rgba(0, 0, 0, 0.08);
    }

    & when (@level =10) {
        box-shadow: 0 24px 38px rgba(0, 0, 0, 0.26), 0 11px 15px rgba(0, 0, 0, 0.08);
    }
}