.site-header(@grid-column: 1; @grid-row: 1) {
    .site-bg-1 {
        z-index: 4;
        grid-column: @grid-column;
        grid-row: @grid-row;
        background: #fff;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 90px;
            background: @header-menu-color-background;
            border-bottom: solid 1px #dfdfdf;
        }
    }

    .site-logo {
        grid-row: 1;
        grid-column: 3;
        z-index: 5;
        align-self: center;
        display: block;
    }

    .site-quicknav{
        grid-row: 2;
        grid-column: 5;
        align-self: center;
        justify-self: end;
        z-index: 5;
    }

    .site-document-title {
        position: relative;
        z-index: 5;
        display: block;
        grid-column: 5;
        grid-row: 1;
        align-self: center;
        justify-self: end;
    }
    .site-primary-menu{
        position: relative;
        grid-column: ~'3/6';
        grid-row: 2;
        justify-self: start;
        align-self: center;
        z-index: 5;
        min-height: 46px;
        padding: 8px 0;
    }
}