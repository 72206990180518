#toc-item-list {
    .dx-treelist-container {
        .dx-treelist-headers,
        .dx-toolbar,
        .dx-treelist-header-panel,
        .dx-treelist-rowsview,
        td {
            border: none;
        }

        td {
            margin-left: -11px;
        }
    }

    .dx-treelist-icon-container {
        display: flex;
        align-items: center;
        z-index: 10;

        .dx-treelist-empty-space {
            height: 18px;

            &::before {
                display: none;
            }

            span {
                &:before {
                    position: static;
                    display: inline;
                    margin: 0;
                }
                position: absolute;
                display: inline-block;
                text-align: center;
                left: 5px;
                height: 18px;
                width: 18px;
                color: white;
                font-size: 18px;
                border-radius: 50%;
                z-index: 10;
            }

            &.dx-treelist-expanded {
                span {
                    background-color: #30379d;
                    line-height: 14px;
                    &::before {
                        content: '-';
                    }
                }
            }

            &.dx-treelist-collapsed {
                span {
                    background-color: #4b55f6;
                    line-height: 17px;
                    &::before {
                        content: '+';
                    }
                }
            }
        }
    }

    .dx-treelist-cell-expandable {
        display: flex;
        align-items: center;
        position: relative;
    }

    .dx-treelist-text-content {
        display: flex;
        flex-grow: 1;
        overflow: visible;
    }
}
