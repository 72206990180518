@breadcrumbs-color: @breadcrumbs-color-text;
@breadcrumbs-color_fade: @breadcrumbs-color-text-fade;

.site-breadcrumbs {
    font-family: Roboto;

    .menu-item-list-container.level-1 {
        position: relative;
        overflow: hidden;
        height: (30/@rem);
    }

    ul {
        .clearfix();
        .reset-list();
        white-space: nowrap;
        min-width: 100%;
        right: 0;
        position: absolute;

        li {
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:last-child {

                a,
                span {
                    color: @breadcrumbs-color;


                    &:after {
                        display: none;
                    }
                }
            }

            a,
            span {
                .animate(color);
                .caption(@breadcrumbs-color_fade);
                cursor: pointer;
                text-decoration: none;
                position: relative;
                padding: 0 (30/@rem) 0 0;

                &:hover {
                    color: @breadcrumbs-color;
                }

                &:after {
                    .v-align();
                    .icon('\E315', 15);
                    right: 8px;
                }
            }
        }
    }
}