// Header_main-menu -- UL items
@header-item-list_level-1_background-color: @header-menu-color-background; // altijd dezelfde kleur als de achtergrond
@header-item-list_level-2_background-color: @header-menu-color-background;
@header-item-list_level-3_background-color: @header-menu-color-background; // Header_main-menu -- li items
@header-sub-nav-container-has-arrow: 'true';
@header-bottom-height: (30/@rem);

// level-1
@header-menu-item__active__level-1_background-color: @header-menu-color-background_active;
@header-menu-item__active__level-1_color: @header-menu-color-text_active;
@header-menu-item__hover__level-1_background-color: @header-menu-color-background_hover;
@header-menu-item__hover__level-1_color: @header-menu-color-text_hover;

// level-2
@header-menu-item__active__level-2_background-color: @header-menu-color-background_active;
@header-menu-item__active__level-2_color: @header-menu-color-text_active;
@header-menu-item__hover__level-2_background-color: @header-menu-color-background_hover;
@header-menu-item__hover__level-2_color: @header-menu-color-text_hover;

// level-3
@header-menu-item__active__level-3_background-color: @header-menu-color-background_active;
@header-menu-item__active__level-3_color: @header-menu-color-text_active;
@header-menu-item__hover__level-3_background-color: @header-menu-color-background_hover;
@header-menu-item__hover__level-3_color: @header-menu-color-text_hover;

//Header_main-menu -- links/buttons
//level-1
@header-link_level-1_padding: 0 (20/@rem);
@header-link_level-1_color: @header-menu-color-text;
@header-link_level-1_font-size: (14/@rem);
@header-link_level-1_font-weight: @font-regular;
@header-link_level-1_font-style: normal;
@header-link_level-1_text-transform: none;
@header-link_level-1_letter-spacing: 25;
// level-2
@header-link_level-2_padding: (14/@rem) (20/@rem);
@header-link_level-2_color: @header-menu-color-text;
@header-link_level-2_font-size: (14/@rem);
@header-link_level-2_font-weight: @font-regular;
@header-link_level-2_font-style: normal;
@header-link_level-2_text-transform: none;
@header-link_level-2_letter-spacing: 25;
// level-3
@header-link_level-3_padding: (14/@rem) (20/@rem);
@header-link_level-3_color: @header-menu-color-text;
@header-link_level-3_font-size: (14/@rem);
@header-link_level-3_font-weight: @font-light;
@header-link_level-3_font-style: italic;
@header-link_level-3_text-transform: none;
@header-link_level-3_letter-spacing: 25;

.dx-menu-item {
    &:not(.dx-menu-item-selected) {

        .dx-menu-item-content,
        .dx-menu-item-text {
            color: black;
        }
    }
}