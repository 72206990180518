.site-side-menu(@grid-column: 1; @grid-row: 1) {
    .site-aside1 {
        z-index: 1;
        grid-column: @grid-column;
        grid-row: @grid-row;
        align-self: start;
        border-radius: 2px;
        margin-left: 16px;
        min-height: 50px;
        background: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26), 0 1px 5px rgba(0, 0, 0, 0.08);
        font-family: Roboto;

        p {
            font-family: Roboto;
        }

        h1, h2, h3, h4, h5, h6 {
            overflow-wrap: break-word;
        }
    }
}