#zion-html {
    @import "./base.less";
    @import "./pagedesigns/grand/sitedesign.less";
    @import "./pagedesigns/grand/pagedesign_01.less";
    @import "./pagedesigns/grand/pagedesign_02.less";
    @import "./pagedesigns/grand/pagedesign_03.less";

    //TODO: including all the things into an #id selector makes it difficult to override them later

    // #region Overrides...
    @site-logo_width: (170 / @rem);
    @site-logo_height: (90 / @rem);

    .site-page-header {
        position: relative;

        >h1 {
            font-family: Roboto;
        }
    }

    .site-main {
        position: relative;
        min-height: 600px;
        font-family: Roboto;

        p {
            font-family: Roboto;
        }

        &.preview-mode {
            padding: 32px;
        }

        &.has-padding {
            padding: 24px;
        }

        &.has-background {
            background: #fff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26), 0 1px 5px rgba(0, 0, 0, 0.08);
            border-radius: 2px;
        }

        a {
            color: #003366;
            text-decoration: none;
            background-color: transparent;
            -webkit-text-decoration-skip: objects;
        }

        a:hover {
            color: #005a6a;
            text-decoration: underline;
        }

        a:visited {
            color: #660066;
            text-decoration: underline;
        }

        a:not([href]):not([tabindex]) {
            color: inherit;
            text-decoration: none;
        }

        a:not([href]):not([tabindex]):focus,
        a:not([href]):not([tabindex]):hover,
        a:not([href]):not([tabindex]):visited {
            color: inherit;
            text-decoration: none;
        }

        a:not([href]):not([tabindex]):focus,
        a:not([href]):not([tabindex]):visited {
            outline: 0;
        }
    }

    .site-logo {
        width: @site-logo_width;
        max-height: @site-logo_height;

        img {
            max-width: 100%;
            max-height: 70px;
        }
    }

    // #endregion
}