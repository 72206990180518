@subtitle-color: @body-header-color-text;
@header-color: @body-header-color-text;

.site-page-header {
    .page-subtitle {
        .p(@subtitle-color);
        margin: 0;
        font-size: (16/@rem);
    }

    .page-title {
        .h1(@header-color);
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    h1 {
        font-size: (32/@rem);
    }
}