.dx-tree-overview {
    display: flex;
    flex: 1;
    padding: 20px;
    background-color: white;

    .dx-treelist-container {
        line-height: 16px;
        color: #414141;
        background-color: transparent;

        .dx-toolbar {
            background-color: transparent;
            border: none;
        }

        .dx-sortable {
            overflow: visible;
        }

        .dx-treelist-header-panel {
            border: none;
        }

        .dx-treelist-headers {
            border: none;
            color: #414141;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .dx-treelist-rowsview {
            overflow: visible;
        }

        td {
            border: none;
            vertical-align: middle;
            background-color: transparent;
        }

        .dx-data-row {
            height: 40px;
            border-bottom: 1px solid #ddd;
            cursor: default;

            &:hover {
                background-color: #f4f4ff;
            }

            .dx-treelist-icon-container {
                display: none;
            }

            .name-cell {
                cursor: pointer;
            }
            
            .contextual-menu-cell {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
