@quicknav-height: (30/@rem);
@quicknav-link-width: (45/@rem);
@quicknav-goto-width: (60/@rem);
@quicknav-quick-links_float: left;
@quicknav-border: none;
@quicknav-link_level-1_color: @quicknav-color;
@quicknav-link_level-1_font-size: (14/@rem);
@quicknav-link_level-1_font-weight: @font-medium;
@quicknav-link_level-1_font-style: normal;
@quicknav-link_level-1_text-transform: none;
@quicknav-link_level-1_padding: 0 (20/@rem);
@quicknav-item__hover__background: transparent;
@quicknav-item__hover__color: @quicknav-color;
@quicknav-input-background: @quicknav-color-background;
@quicknav-input-color: @quicknav-color;
@quicknav-input-border: solid 1px @quicknav-color-border;
@quicknav-input__focus__color: transparent;
@quicknav-input__focus__background: @quicknav-color-background-active;
@quicknav_breakpoint: @responsive_breakpoint;


.quick-links {
    & when(@quicknav-quick-links_float =left) {
        float: left;
        border-left: @quicknav-border;
    }

    & when(@quicknav-quick-links_float =right) {
        float: right;
        border-right: @quicknav-border;
    }

    .menu-item-list-container {
        & when(@quicknav-quick-links_float =left) {
            border-right: @quicknav-border;
        }

        & when(@quicknav-quick-links_float =right) {
            border-left: @quicknav-border;
        }

        .menu-item-list {
            .reset-list();

            .menu-item {
                &.level-1 {
                    &:hover {

                        .menu-item-link,
                        .menu-item-popup {
                            background: @quicknav-item__hover__background;
                            color: @quicknav-item__hover__color;
                        }
                    }
                }

                .menu-item-container,
                .menu-item-link,
                .menu-item-popup {
                    // Reset css
                    display: block;
                    padding: 0;
                    text-decoration: none;
                    border: none;
                    background: none;
                    text-align: left;
                    width: 100%;
                    position: relative;
                    .animate(background; color; border-color);

                    &.level-1 {
                        height: @quicknav-height;
                        line-height: @quicknav-height;
                        padding: @quicknav-link_level-1_padding;
                        color: @quicknav-link_level-1_color;
                        font-size: @quicknav-link_level-1_font-size;
                        font-weight: @quicknav-link_level-1_font-weight;
                        font-style: @quicknav-link_level-1_font-style;
                        text-transform: @quicknav-link_level-1_text-transform;
                    }
                }

                .menu-item-popup.level-1 {
                    padding-right: 40px;

                    &:after {
                        .icon(@icon-filter_none, 14);
                        right: 13px;
                        .v-align();
                    }
                }
            }
        }
    }
}

.site-quicknav {

    & when(@quicknav-quick-links_float =left) {
        float: right;
        border-right: @quicknav-border;
    }

    & when(@quicknav-quick-links_float =right) {
        float: left;
        border-left: @quicknav-border;
    }

    .btn,
    .search-button {
        cursor: pointer;
        display: block;
        float: left;
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
        text-decoration: none;
        height: @quicknav-height;
        line-height: @quicknav-height;
        width: @quicknav-link-width;
        position: relative;
        color: @quicknav-link_level-1_color;
        .animate(background);

        &:before {
            .icon(@icon-search);
            .v-align();
            width: 100%;
            text-align: center;
            left: 0;
            right: 0;
        }

        &.prev:before {
            content: @icon-keyboard_arrow_left;
        }

        &.next:before {
            content: @icon-keyboard_arrow_right;
        }

        &:hover {
            background: @quicknav-item__hover__background;
            color: @quicknav-item__hover__color;
        }
    }

    #goto-page {
        float: left;
        width: @quicknav-goto-width;

        input {
            .animate(color; background);
            height: @quicknav-height;
            line-height: @header-bottom-height;
            width: 100%;
            border: @quicknav-input-border;
            border-radius: 2px;
            padding: 0;
            text-align: center;
            background: @quicknav-input-background;
            color: @quicknav-input-color;

            // .placeholder( {
            //         color: @quicknav-input-color;
            //     }

            // );

            // .placeholder-focus( {
            //         color: @quicknav-input__focus__color;
            //     }

            // );

            &:focus {
                outline: none;
                background: @quicknav-input__focus__background
            }
        }
    }
}



/* Numbered textfields */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


@media (min-width: @responsive_breakpoint) {
    .site-searchbox {
        margin-top: 60px;
        padding: 0 (20/@rem);
        position: relative;
        width: 100%;
    }

    .site-searchbox-input {
        height: (40/@rem);
        position: relative;
        border: none;
        width: 100%;
        background: @quicknav-input-background;
        border: @quicknav-input-border;
        border-radius: 2px;
        padding: 0 (20/@rem);
        color: @quicknav-link_level-1_color;
    }

    .site-searchbox-submit {
        .reset-btn();
        float: left;
        position: absolute;
        right: (30/@rem);
        top: 0;
        background: transparent;
        height: (40/@rem);
        width: (40/@rem);

        &:before {
            .icon(@icon-search);
            .v-align();
            text-align: center;
            width: 100%;
            left: 0;
            color: fade(@quicknav-link_level-1_color, 54%);
        }
    }

    .search-suggestions {
        display: none;
    }
}