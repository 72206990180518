@import "../../base-theme.less";
@import "../sectioncontainers/site_header.less";

.body {
  display: grid;
  grid-template-columns: calc(~"50% - (1250px / 2)") 40px minmax(20px, 50px) 1fr 312px 40px calc(~"50% - (1250px / 2)");
  grid-template-rows: 90px auto 24px auto auto 24px auto 1fr 160px;
}

@col: ~"1 / 8";
@row: ~"1 / 3";

.site-header(@grid-column: @col; @grid-row: @row;);
