.site-breadcrumbs(@grid-column: 1; @grid-row: 1) {
    .site-breadcrumbs{
        grid-column: @grid-column;
        grid-row: @grid-row;
        align-self: start;
    }
}

.site-breadcrumbs-icon(@grid-column: 1; @grid-row: 1) {
    .breadcrumbs-with-page-icon{
        grid-column: @grid-column;
        grid-row: @grid-row;
        align-self: start;
    }
}