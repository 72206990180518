#hierarchy-items-overview {
    display: flex;
    flex: 1;
    padding: 20px;
    background-color: white;

    .dx-treelist-container {
        line-height: 16px;
        color: #414141;
        background-color: transparent;

        .dx-toolbar {
            background-color: transparent;
            border: none;
        }

        .dx-sortable {
            overflow: visible;
        }

        .dx-treelist-header-panel {
            border: none;
        }

        .dx-treelist-headers {
            border: none;
            color: #414141;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .dx-treelist-rowsview {
            overflow: visible;
        }

        td {
            border: none;

            .dx-treelist-icon-container {
                flex-shrink: 0;
            }
        }

        .dx-data-row {
            height: 40px;
            border-bottom: 1px solid #ddd;
            cursor: default;

            &:hover {
                background-color: #f4f4ff;
            }
        }
    }

    .contextual-menu-cell {
        display: flex;
        justify-content: flex-end;
    }

    .overflow-cell {
        display: flex;
        flex-direction: row;
        flex: 1;
        font-weight: 500;

        .overflow-cell-icon {
            display: flex;
            align-items: center;
            margin: 0 5px 0 0;
        }

        .overflow-cell-label {
            white-space: nowrap;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;
            padding: 0 5px;
            margin-top: 0.1rem;
        }

        .cursor-pointer {
            cursor: pointer;
        }
    }

    .dx-treelist-rowsview td {
        vertical-align: middle;
        background-color: transparent;
    }

}