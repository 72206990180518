// Typography
//
//
// USAGE:
// .h1(light/dark/#f00);
//
//
.h1(@contrast: @font-heading_1, @font-size: (34/@rem)) {
    font-size: @font-size;
    font-weight: @font-regular;
    line-height: (40/@rem);
    .letter-spacing(0);
    margin-bottom: (25/@rem);

    & when(@contrast =light) {
        color: fade(#000000, 54%);
    }

    & when(@contrast =dark) {
        color: fade(#ffffff, 100%);
    }

    & when(iscolor(@contrast)) {
        color: @contrast;
    }
}

.h2(@contrast: @font-heading_2, @font-size: (24/@rem)) {
    font-size: @font-size;
    font-weight: @font-bold;
    line-height: (32/@rem);
    .letter-spacing(0);
    margin-bottom: (15/@rem);

    & when(@contrast =light) {
        color: fade(#000000, 100%);
    }

    & when(@contrast =dark) {
        color: fade(#ffffff, 100%);
    }

    & when(iscolor(@contrast)) {
        color: @contrast;
    }
}

.h3(@contrast: @font-heading_3, @font-size: (20/@rem)) {
    font-size: @font-size;
    font-weight: @font-bold;
    line-height: (28/@rem);
    .letter-spacing(5);
    margin-bottom: (15/@rem);

    & when(@contrast =light) {
        color: fade(#000000, 87%);
    }

    & when(@contrast =dark) {
        color: fade(#ffffff, 100%);
    }

    & when(iscolor(@contrast)) {
        color: @contrast;
    }
}

.h4(@contrast: @font-heading_4, @font-size: (16/@rem)) {
    font-size: @font-size;
    font-weight: @font-bold;
    line-height: (28/@rem);
    .letter-spacing(10);
    margin-bottom: (10/@rem);

    & when(@contrast =light) {
        color: fade(#000000, 87%);
    }

    & when(@contrast =dark) {
        color: fade(#ffffff, 100%);
    }

    & when(iscolor(@contrast)) {
        color: @contrast;
    }
}

.p(@contrast: @font-text, @font-size: (16/@rem)) {
    .letter-spacing(10);
    font-size: @font-size;
    margin-bottom: (10/@rem);

    & when(@contrast =light) {
        color: fade(#000000, 87%);
    }

    & when(@contrast =dark) {
        color: fade(#ffffff, 100%);
    }

    & when(iscolor(@contrast)) {
        color: @contrast;
    }
}

.caption(@contrast: @font-caption, @font-size: (12/@rem)) {
    font-weight: @font-regular;
    font-size: @font-size;
    line-height: (16/@rem);
    .letter-spacing(20);
    margin-bottom: 0;

    & when(@contrast =light) {
        color: fade(#000000, 54%);
    }

    & when(@contrast =dark) {
        color: fade(#ffffff, 70%);
    }

    & when(iscolor(@contrast)) {
        color: @contrast;
    }
}

// Buttons
//
//
// USAGE:
// .icon-button(color/#f00, @icon-test, size/18px, basic/#f00);
// .button(flat/raised, background/#f00, color/#f00, basic/#f00);
// .floating-button(large/small, background/#f00, color/#f00, icon, size/18px, basic/#f00);
//
//
.ripple-effect(@ripple: fade(#000, 20%)) {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
        background-image: radial-gradient(circle, @ripple 10%, transparent 10.01%);
        background-repeat: no-repeat;
        background-position: 50%;
        opacity: 0;
        -webkit-transform: scale(10, 10);
        -ms-transform: scale(10, 10);
        transform: scale(10, 10);
        -webkit-transition: transform 0.5s, opacity 1s;
        -o-transition: transform 0.5s, opacity 1s;
        transition: transform 0.5s, opacity 1s;
    }

    &:active:before {
        transform: scale(0, 0);
        opacity: 1;
        transition: 0s;
    }
}

.icon-button(@color: fade(#000, 54%), @icon: '\E87D', @font-size: 20, @ripple: default) {
    .reset-btn();
    color: @color;
    width: (48/@rem);
    height: (48/@rem);
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    display: inline-flex;

    &:hover {
        color: @color;
    }

    &:after {
        .icon(@icon, @font-size);
        .v-align();
        left: 0;
        right: 0;
    }

    & when(iscolor(@ripple)) {
        .ripple-effect(@ripple );
    }

    & when not (iscolor(@ripple)) {
        .ripple-effect(fade(@color, 20%));
    }
}

.button(@type: flat, @background: #4786FF, @color: #fff, @ripple: default) {
    .reset-btn(@padding: (5/@rem) (16/@rem));
    .animate(box-shadow; background);
    font-weight: @font-bold;
    color: @color;
    border-radius: (2/@rem);
    .letter-spacing(10);
    text-transform: uppercase;
    display: inline-block;
    display: inline-flex;
    text-align: left;

    & when (@type =flat) {
        &:hover {
            background: fade(@color, 15%);
        }

        &:active {
            outline: none;
            background: fade(@color, 25%)
        }

        & when(iscolor(@ripple)) {
            .ripple-effect(@ripple );
        }

        & when not (iscolor(@ripple)) {
            .ripple-effect(fade(@color, 40%));
        }
    }

    & when (@type =raised) {
        background: @background;

        &:hover {
            .shadow(1);
        }

        &:active {
            outline: none;
            .shadow(6);
        }

        & when(iscolor(@ripple)) {
            .ripple-effect(@ripple );
        }

        & when not (iscolor(@ripple)) {
            .ripple-effect(fade(contrast(@background ), 20%));
        }
    }
}

.floating-button(@type: large, @background: #4786FF, @color: #fff, @icon: @icon-add, @font-size: 22, @ripple: default) {
    .reset-btn(@background-color: @background);
    .shadow(5);
    .animate(box-shadow; background);
    border-radius: 50%;
    color: @color;
    display: inline-block;
    display: inline-flex;
    text-align: center;

    &:after {
        .icon(@icon, @font-size);
        .v-align();
        left: 0;
        right: 0;
    }

    &:active {
        outline: none;
        background: darken(@background, 5%);
        .shadow(8);
    }

    & when(@type =large) {
        width: (56/@rem);
        height: (56/@rem);
    }

    & when(@type =small) {
        width: (40/@rem);
        height: (40/@rem);
    }

    & when(iscolor(@ripple)) {
        .ripple-effect(@ripple );
    }

    & when not (iscolor(@ripple)) {
        .ripple-effect(fade(contrast(@background ), 20%));
    }
}