@import './basics/styleguide.less';
@import './basics/reboot.less';
@import './basics/icons.less';
@import './basics/mixins.less';
@import './controls/main-nav.less';
@import './controls/document-title.less';
@import './controls/page-title.less';
@import './controls/breadcrumbs.less';
@import './controls/quick-nav.less';
@import './controls/footer.less';
@import './base-theme.less';

@import './controls/treeview.less';

@background-color: #eee;

@border-color: rgba(0, 0, 0, .1);

// Font definitions
@text-font: 'Roboto', sans-serif;
@icon-font: 'Material Icons';

// Font weights
@font-thin: 100;
@font-light: 300;
@font-regular: 400;
@font-medium: 500;
@font-bold: 700;
@font-black: 900;

// Font size
@base-font-size: 16;
@rem: @base-font-size * 1rem; // Use {pixels} / @rem. e.g.: (25/@rem)

@responsive_breakpoint: 700px;

.body {
    background: #eee;
    font-family: 'Roboto', sans-serif;
}

.body:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 350px;
    background-color: @body-header-color-background;
    // z-index: -1; This is not according with the back-end styles files.
}

.dx-widget,
.dxc-hole-template,
.dxc-title>text {
    font-family: 'Roboto', sans-serif !important;
}


// demo purpose
.site-banner {
    min-height: 300px;
}

#main {
    min-height: 600px;

    h1 {
        .h1(@font-size: @settings-heading1-font-size/@rem);
    }

    h2 {
        .h2(@font-size: @settings-heading2-font-size/@rem);
    }

    h3 {
        .h3(@font-size: @settings-heading3-font-size/@rem);
    }

    p {
        .p(@font-size: @settings-text-font-size/@rem);
    }

}