.site-bg-2(@grid-column: 1; @grid-row: 1;){
    .site-bg-2{
        grid-column: @grid-column;
        grid-row: @grid-row;
        z-index: -1;

        // start: This is not according with the back-end styles files.

        //border-radius: 2px;
        //background: #fff;
        //box-shadow: 0 2px 4px rgba(0,0,0,.26),0 1px 5px rgba(0,0,0,.08);

        // end: This is not according with the back-end styles files.
    }
}