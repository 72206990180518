@site-footer-link-padding: (30/@rem);

@site-footer-link-direction-font-size: (12/@rem);
@site-footer-link-caption-font-size: (16/@rem);

@site-footer-link-direction-font-weight: @font-medium;
@site-footer-link-caption-font-weight: @font-regular;

@site-footer-link-direction-color: @footer-link-direction-color;
@site-footer-link-caption-color: @footer-link-caption-color;
@site-footer-arrows-color: @footer-arrows-color;

@site-footer-arrows-padding: (60/@rem);

.site-footer {
    .link {
        width: 50%;
        padding: @site-footer-link-padding;
        float: left;
        text-decoration: none;
        position: relative;

        .direction,
        .caption {
            display: block;
        }

        .direction {
            font-weight: @site-footer-link-direction-font-weight;
            font-size: @site-footer-link-direction-font-size;
            color: @site-footer-link-direction-color;
        }

        .caption {
            font-weight: @site-footer-link-caption-font-weight;
            font-size: @site-footer-link-caption-font-size;
            color: @site-footer-link-caption-color;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &.prev {
            text-align: left;
            padding-left: @site-footer-arrows-padding;

            &:before {
                .icon(@icon-arrow_back, 30);
                .v-align();
                color: @site-footer-arrows-color;
                left: 0;
            }
        }

        &.next {
            text-align: right;
            padding-right: @site-footer-arrows-padding;

            &:after {
                .icon(@icon-arrow_forward, 30);
                .v-align();
                color: @site-footer-arrows-color;
                right: 0;
            }
        }
    }
}

.site-footer {
    text-align: center;
    font-size: 11px;

    .copyright,
    .privacy-statement-link,
    .sitemap-link {
        color: rgba(0, 0, 0, 0.8);
        letter-spacing: 0.01em;
        display: inline-block;
        line-height: normal;
    }
}

@media (max-width: @responsive_breakpoint) {
    .site-footer {
        padding: 0 (20/@rem);
    }

    .site-footer .link .caption {
        display: none;
    }
}