.dx-data-row, .dx-htmleditor-content, .dx-item-content {
    table {
        th, td {
            overflow-wrap: normal;
            text-overflow: ellipsis;
        }

        p, ol, ul, li {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
   
    a {
        pointer-events: auto;
    }
}

.lias-tileview.rounded-tiles .dx-tile {
    border-radius: 4px;
}

.dx-popup-content.dx-popup-content-scrollable.dx-dropdownmenu-list.dx-scrollable.dx-scrollview.dx-visibility-change-handler.dx-scrollable-vertical.dx-scrollable-simulated.dx-list.dx-widget.dx-collection {
    height: auto !important;
    max-height: 250px !important;
}

.dx-toolbar .dx-toolbar-items-container {
    height: 3rem;
}


.dx-popover-wrapper .dx-popover-arrow {
    display: block !important;
}

.dx-tooltip-wrapper.dx-popover-wrapper .dx-popover-arrow {
    display: block !important;
}

.dx-popover-wrapper .dx-popover-arrow::after, .dx-popover-wrapper.dx-popover-without-title .dx-popover-arrow::after {
    background: #fff;
} 


.lias-context-menu {
    color: #4B55F6 !important;
}

.lias-context-menu .dx-context-menu .dx-menu-item, 
.lias-context-menu .dx-menu-item {
    font-size: 1rem;
    color: inherit!important;
    line-height: 2rem;
    padding-left: 0px;
    text-overflow: ellipsis;
    font-weight: 800;
    text-transform: uppercase;
    text-overflow: ellipsis;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;   
    max-width: 250px;
}

.dx-menu-base .dx-menu-item .dx-menu-item-content .dx-menu-item-text {
    overflow:hidden !important;
}

.dx-context-menu .dx-menu-item .dx-menu-item-content .dx-menu-item-popout-container .dx-menu-item-popout {
    color: inherit!important;
}

.lias-menu-navigation {
    .dx-menu-item-selected {
        background-color: var(--submenuBackgroundColor);
        color: var(--submenuFontColor);
    }
}

.dx-dropdowneditor .dx-list-group-header {
    font-weight: bold;
}

.dx-sortable-clone.dx-sortable-dragging .dx-tab.dx-tab-selected, 
.dx-sortable-clone.dx-sortable-dragging .dx-tab.dx-state-active,
.dx-sortable-clone.dx-sortable-dragging .dx-tab.dx-state-active i.dx-icon.dx-icon-close
{
    color: #414141;
    background-color: #f4f4f8;
    font-size: 14px;
}

.dx-sortable-clone.dx-sortable-dragging .dx-tab.dx-tab-selected .dx-inkripple, 
.dx-sortable-clone.dx-sortable-dragging .dx-tab.dx-state-active .dx-inkripple
{
    display: none;
}


.dx-accordion-item,.richtext-viewer {
    ul {
        list-style-type: disc;
    }
    ol {
        counter-reset: level1 level2 level3;
        ol li {
            counter-increment: level1;
            &::marker {
                content: counter(level1, lower-alpha) ". ";
                }
    
            ol li {
                counter-increment: level2;
    
                &::marker {
                    content: counter(level2, lower-roman) ". ";
                }
    
                ol li {
                    counter-increment: level3;
    
                    &::marker {
                        content: counter(level3) ". ";
                    }
                }
            }
        }
    }
}

.dx-invalid-message {
    display: none;
}


.ls-popup .dx-popup-normal .dx-popup-content {
    background-color: #F4F4F8 ;
}

.ls-popup .dx-popup-title {
    color: #414141;
    font-size: 20px;
    font-weight: bold;
}

.ls-popup .dx-popup-title.dx-toolbar {
    padding: 16px;
}

.ls-popup .dx-popup-title .dx-toolbar-label {
    color: #414141;
    font-size: 20px;
    font-weight: bold;
    max-width: fit-content !important;
}

.ls-popup .dx-popup-content {
    background-color: #F4F4F8 ;
}

.ls-popup .dx-popup-title .dx-button-has-icon .dx-icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: #4B55F6;
}

.lias-popover.arrow-invisible .dx-popover-arrow {
    display: none !important;
}

.lias-popover.background-transparent .dx-overlay-content {
    background: transparent;
    box-shadow: none;
    border-radius: unset;
}

.table-wrapper {
    overflow: auto;
}

.dx-icon-custom-style {
    font-size: 24px;
    color: #4B55F6;
}


.custom-tab-container {
    display: flex;
    justify-content: flex-start; 
    position: relative; 
    width: 100%; 
}


.custom-tab-container::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px; 
    background-color: #d3d3d3; 
    z-index: 1; 
}

.lias-navigation-tabs {
    display: flex; 
    justify-content: flex-start; 
    background-color: transparent; 
    padding: 0; 
}

.lias-navigation-tabs.dx-tabs-styling-mode-primary .dx-tab {
    flex: none; 
    background-color: transparent; 
    border: none; 
    position: relative; 
    padding: 10px 20px; 
    transition: color 0.3s ease; 
}

.lias-navigation-tabs.dx-tabs-styling-mode-secondary .dx-tab {
    flex: none; 
    background-color: transparent; 
    border: none; 
    position: relative; 
    padding: 10px 20px 10px 0px; 
    transition: color 0.3s ease; 
}

.lias-navigation-tabs .dx-tab .dx-tab-text {
    text-transform: none;
    font-size: inherit;
    font-weight: 600;
}

.lias-navigation-tabs .dx-tab.dx-item-selected,
.lias-navigation-tabs .dx-tab.dx-state-active,
.lias-navigation-tabs .dx-tab.dx-state-focused,
.lias-navigation-tabs .dx-tab.dx-state-hover
{
    background-color: transparent !important; 
}

.lias-navigation-tabs .dx-tab .dx-inkripple {
    display: none; 
}

.lias-navigation-tabs.dx-tabs-styling-mode-primary .dx-tab::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px; 
    z-index: 2; 
    bottom: 0; 
    left: 0;   
    background-color: transparent; 
    transition: background-color 0.3s ease-in-out; 
}

.lias-navigation-tabs.dx-tabs-styling-mode-secondary.dx-tab-indicator-position-bottom .dx-tab.dx-tab-selected .dx-tab-content::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px; 
    z-index: 2; 
    right: 0;
    left: 0;   
    background-color: #4B55F6;
    bottom: -10px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;   
}

.lias-navigation-tabs .dx-tab.dx-item-selected {
    font-weight: bold; 
    color: #4B55F6; 
}

.lias-navigation-tabs.dx-tabs-styling-mode-primary .dx-tab.dx-item-selected::after {
    background-color: #4B55F6!important; 
}

.lias-navigation-tabs.dx-tabs-styling-mode-primary.dx-tab-indicator-position-bottom .dx-tab-selected::after {
    background-color: #4B55F6!important; 
}

.lias-navigation-tabs .dx-tab.dx-tab-selected,
.lias-navigation-tabs .dx-tab.dx-tab-selected .dx-icon {
  color: #4B55F6;
}

.lias-modal-dialog-content .dx-scrollview-content {
    max-height: 65vh;
    padding-right: 1rem;
}

.dx-lias-fieldset:first-child {
    margin-top: 0;
}

.dx-lias-fieldset > .dx-lias-field {
    display: block
}
  
.dx-lias-fieldset > .dx-lias-field > .dx-field-label {
    float: none;
    font-weight: 600;
}
  
.dx-lias-fieldset > .dx-lias-field > .dx-field-value {
    float: none;
}

.dx-pivotgrid .dx-pivotgrid-area-data .dx-row-total, .dx-pivotgrid .dx-pivotgrid-vertical-headers .dx-row-total,
.dx-pivotgrid .dx-pivotgrid-area.dx-pivotgrid-horizontal-headers td, .dx-pivotgrid .dx-pivotgrid-area.dx-pivotgrid-vertical-headers td
.dx-pivotgrid .dx-pivotgrid-area-data tbody tr:first-child .dx-grandtotal, .dx-pivotgrid .dx-pivotgrid-area-data tbody tr:first-child .dx-total
 {
    font-weight: 600!important;
}