@import "../sectioncontainers/site_page-header.less";
@import "../sectioncontainers/site_breadcrumbs.less";
@import "../sectioncontainers/site_banner.less";
@import "../sectioncontainers/site_main.less";
@import "../sectioncontainers/site_sidebar.less";
@import "../sectioncontainers/site_footer.less";
@import "../sectioncontainers/site_page-icon.less";
@import "../sectioncontainers/site_bg-2.less";

@import "./pagedesignIdentifiers.less";

.@{pagedesign_03} {
  @col1: ~"3 / 6";
  @row1: ~"4 / 5";
  .site-page-header(@grid-column: @col1; @grid-row: @row1;);

  @col2: ~"3 / 6";
  @row2: ~"5";
  .site-breadcrumbs(@grid-column: @col2; @grid-row: @row2;);

    
  @col0: ~"3 / 4";
  @row0: ~"4 / 6";
  .site-page-icon(@grid-column: @col0; @grid-row: @row0;);

  @col01: ~"4 / 6";
  @row01: ~"4 / 5";
  .site-page-header-icon(@grid-column: @col01; @grid-row: @row01;);

  @col02: ~"4 / 6";
  @row02: ~"5";
  .site-breadcrumbs-icon(@grid-column: @col02; @grid-row: @row02;);


  @col3: ~"3 / 5";
  @row3: ~"7 / 9";
  .site-main(@grid-column: @col3; @grid-row: @row3;);

  @col4: ~"5";
  @row4: ~"7 / 9";
  .site-side-menu(@grid-column: @col4; @grid-row: @row4;);

  @col5: ~"3 / 6";
  @row5: ~"9";
  .site-footer(@grid-column: @col5; @grid-row: @row5;);

  @col6: ~"3 / 5";
  @row6: ~"7 / 9";
  .site-bg-2(@grid-column: @col6; @grid-row: @row6;);
}

//TODO: tech debt to handle responsiveness in a more organized manner.
.responsive-pagedesign3 {
    #zion-body {
      grid-template-rows: 90px auto 24px auto auto 24px auto auto 1fr 80px;
      grid-template-columns: 0 10px 0 93% 0 10px 0;

      .site-aside1 {
          grid-column: ~"3 / 5";
          grid-row: 9;
          margin-left: 0;
          margin-top: 16px;
          overflow: auto;
          padding-right: 0;

          + div {
              overflow: auto;
          }
      }

      .site-footer {
          grid-row: 10;
      }
    }
  }
