.spaces(@key, @value) {
  //remove the quotes from the string
  @thekey: ~"@{key}";

  .pt-@{thekey} {
    padding-top: @value !important;
  }

  .pb-@{thekey} {
    padding-bottom: @value !important;
  }

  .pl-@{thekey} {
    padding-left: @value !important;
  }

  .pr-@{thekey} {
    padding-right: @value !important;
  }

  .p-@{thekey} {
    padding: @value !important;
  }

  .mt-@{thekey} {
    margin-top: @value !important;
  }

  .mb-@{thekey} {
    margin-bottom: @value !important;
  }

  .ml-@{thekey} {
    margin-left: @value !important;
  }

  .mr-@{thekey} {
    margin-right: @value !important;
  }

  .m-@{thekey} {
    margin: @value !important;
  }
}

.positions(@key) {
  //remove the quotes from the string
  @thekey: ~"@{key}";

  .position-@{thekey} {
    position: @thekey !important;
  }
}

body {
  height: 100%;
  color: #414141;
  background-color: #f4f4f8;
  font: 400 1rem/1rem "Nunito";
  margin: 0;

  >#app {
    position: absolute;
    height: 100%;
  }

  a {
    cursor: pointer;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .filepond--credits {
    display: none;
  }

  .filepond--wrapper {
    width: fill-available;
  }

  .dx-widget,
  .dxc-hole-template,
  .dxc-title>text {
    font-family: "Nunito" !important;
  }

  .dx-widget input {
    font-family: inherit;
  }

  .dx-lookup-arrow:before {
    font-family: 'Material Icons';
    content: '\e5cf';
  }

  .spaces("025", 0.25rem);
  .spaces("050", 0.50rem);
  .spaces("075", 0.75rem);
  .spaces("100", 1.00rem);
  .spaces("150", 1.50rem);

  .positions("absolute");
  .positions("relative");
  .positions("fixed");
}