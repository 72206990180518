.site-page-header(@grid-column: 1; @grid-row: 1) {
    .site-page-header{
        grid-column: @grid-column;
        grid-row: @grid-row;
        align-self: end;
    }
}

.site-page-header-icon(@grid-column: 1; @grid-row: 1) {
    .header-with-page-icon{
        grid-column: @grid-column;
        grid-row: @grid-row;
        align-self: end;
    }
}