#measure-moments-list {
    display: flex;
    flex: 1;
    padding: 20px;
    background-color: white;

    .dx-treelist-container {
        line-height: 16px;
        color: #414141;
        background-color: transparent;

        .dx-toolbar {
            background-color: transparent;
            border: none;
        }

        .dx-sortable {
            overflow: visible;
        }

        .dx-treelist-header-panel {
            border: none;
        }

        .dx-treelist-headers {
            border: none;
            color: #414141;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .dx-treelist-rowsview {
            overflow: visible;
        }

        td {
            border: none;
        }

        .dx-data-row {
            height: 40px;
            border-bottom: 1px solid #ddd;
            cursor: default;

            &:hover {
                background-color: #f4f4ff;
            }
        }
    }
}

#measure-moments-list .dx-treelist-rowsview td {
    vertical-align: middle;
    background-color: transparent;
}

.name-cell {
    display: flex;
    flex-direction: row;
    flex: 1;

    .name-cell-label {
        display: flex;
        align-items: center;
        padding: 0 5px;
        font-weight: 500;
        cursor: pointer;
    }
}

.status-cell {
    font-weight: 500;
}

.base-year-cell{
    display: flex;
}

.start-date-cell .end-date-cell {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.contextual-menu-cell {
    display: flex;
    justify-content: flex-end;
}

