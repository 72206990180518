.lias-treeview-item-list {
    .dx-treelist-container {
        .dx-treelist-headers,
        .dx-toolbar,
        .dx-treelist-header-panel,
        .dx-treelist-rowsview,
        td {
            border: none;
        }

        // td:first-child {
        //    margin-left: -11px;
        // }

        .dx-header-row {
            .dx-treelist-text-content {
                font-size: 14px;
                font-weight: 700;
                color: #414141;
                margin: 0;
                padding: 0;
                display: flex;
            }
        }

        .dx-treelist-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after {
            border: none;
        }

        .dx-column-lines {
            border-bottom: 1px solid #BCBCC680;
            td {
                border: none;
            }
        }
    }

    .dx-treelist-icon-container {
        .dx-treelist-empty-space {
            height: 18px;
            span {
                &:before {
                    position: static;
                    display: inline-block;
                    margin: 0;
                    margin-top: 2px;
                    margin-left: -1px;
                }
                position: absolute;
                text-align: center;
                left: 1px;
                height: 16px;
                width: 16px;
                color: white;
                font-size: 12px;
                border-radius: 50%;
                z-index: 10;
            }

            &.dx-treelist-expanded {
                span {
                    background-color: #30379d;
                    line-height: 12px;
                    &::before {
                        content: "\f074";
                    }
                }
            }

            &.dx-treelist-collapsed {
                span {
                    background-color: #4b55f6;
                    line-height: 12px;
                    &::before {
                        content: "\f00b";
                    }
                }
            }           
        }
 
        &.dx-editor-inline-block {
           .dx-checkbox-container {
                margin-left: 10px;   
            }
        }
    }   

    .dx-treelist-select-all {
        .dx-checkbox-container {
            display: none;
        }
    }
    .dx-checkbox-container {
        display: inline-block; 
        margin-top: -4px;
        padding-bottom: 4px;
        font-size: 14px;
    }
    .dx-treelist-cell-expandable {
        display: flex;
        align-items: center;
        position: relative;
    }
    .dx-treelist-text-content {
        display: flex;
        flex-grow: 1;
        overflow: visible;
    }
    .mr-half {
        margin-right: 0.5rem;
    }

    .dx-checkbox-icon {
        background-color: #fff;
        border: 1px solid #4b55f6;
        border-radius: 4px;
        text-align: center;
        font-weight: 600;
        height: 20px;
        width: 20px;
    } 

    .dx-checkbox-indeterminate .dx-checkbox-icon {
        background-color: #4b55f6;
        color: #fff;  
        font-size: 14px;      
        &::before {
            content: "\f074";
        }
    }
    .dx-checkbox-checked .dx-checkbox-icon {
        background-color: #4b55f6;
        color: #fff;
    }

    .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines) > td, 
    .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td {
        border-bottom: none;
        border-top: none;
    }

    .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td, 
    .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td, 
    .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td, 
    .dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td
    {
        background-color: transparent;
    }

}

